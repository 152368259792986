import PropTypes from 'prop-types';
import useVkAppIntegrationReady, { SIGNUP_FORM } from 'src/hooks/useVkAppIntegrationReady';
const VKAppListener = _ref => {
  let {
    form,
    children,
    fields = ['firstName', 'lastName']
  } = _ref;
  useVkAppIntegrationReady(SIGNUP_FORM, fields, form);
  return children;
};
VKAppListener.propTypes = {
  form: PropTypes.object,
  fields: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node
};
export default VKAppListener;